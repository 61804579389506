<template>
  <div class="section">
    <div class="is-size-4 mb-2">Event Series List</div>
    <div class="is-flex">
      <b-field label="Title Filters" class="my-1">
        <b-input
          ref="inputTitle"
          v-model="searchStringTitle"
          class="mr-2"
          placeholder="Title"
          :loading="$apollo.loading"
          @input="updateSearch('inputTitle')"
        />
      </b-field>
      <b-field label="Organizer Filters" class="my-1">
        <b-input
          ref="inputHost"
          v-model="searchStringHost"
          class="mr-2"
          placeholder="Organizer Name"
          :loading="$apollo.loading"
          @input="updateSearch('inputHost')"
        />
      </b-field>
      <b-field label="Cancelled Filters" class="my-1 mr-2">
        <div class="cancel-dropdown">
          <b-dropdown ref="dropdown" aria-role="list" position="is-bottom-left">
            <template #trigger>
              <b-button type="has-text-grey-dark" icon-right="chevron-down">
                <span v-if="onlyShowScheduled">Scheduled</span>
                <span v-else-if="onlyShowCancelled">Cancelled</span>
                <span v-else>Any</span>
              </b-button>
            </template>
            <b-dropdown-item @click="selectAny">
              Any
            </b-dropdown-item>
            <b-dropdown-item @click="selectScheduled">
              Scheduled
            </b-dropdown-item>
            <b-dropdown-item @click="selectCancelled">
              Cancelled
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </b-field>
      <b-field label="Virtual Filters" class="my-1">
        <div class="cancel-dropdown">
          <b-dropdown ref="dropdown" aria-role="list" position="is-bottom-left">
            <template #trigger>
              <b-button type="has-text-grey-dark" icon-right="chevron-down">
                <span v-if="onlyShowPhysical">Physical</span>
                <span v-else-if="onlyShowVirtual">Virtual</span>
                <span v-else>Any</span>
              </b-button>
            </template>
            <b-dropdown-item @click="selectAnyVenue">
              Any
            </b-dropdown-item>
            <b-dropdown-item @click="selectPhysicalVenue">
              Physical
            </b-dropdown-item>
            <b-dropdown-item @click="selectVirtualVenue">
              Virtual
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </b-field>
    </div>
    <b-table
      :loading="$apollo.loading"
      :data="data"
      striped
      hoverable
      :total="dataCount"
      :per-page="limit"
      :current-page="1"
      backend-sorting
      backend-pagination
      paginated
      :is-row-checkable="row => row.author_user_id === 'meetup_migrate_user'"
      checkable
      :checked-rows.sync="checkedRows"
      @page-change="onPageChanged"
      @sort="onSort"
    >
      <b-table-column v-slot="props" field="slug" label="_" sortable>
        {{ props.row.slug ? "o" : "" }}
      </b-table-column>
      <b-table-column v-slot="props" field="title" label="Title (Hyperlinked)" sortable>
        <a v-show="!props.row.cancelled_by_user_at && props.row.upcoming_events && props.row.upcoming_events.length > 0" @click="onClickTitle(props.row)">
          {{ format_title(props.row) }}
        </a>
        <span v-show="props.row.cancelled_by_user_at || !props.row.upcoming_events || props.row.upcoming_events.length == 0">{{ format_title(props.row) }}</span>
      </b-table-column>
      <b-table-column v-slot="props" field="host" label="Organizer">
        {{ props.row.organizers[0].user.username }}
      </b-table-column>
      <b-table-column v-slot="props" field="is_virtual" label="Virtual?" sortable centered>
        {{ props.row.venue.is_virtual ? "Y" : "N" }}
      </b-table-column>
      <b-table-column v-slot="props" field="is_migrated" label="Migrated?" sortable centered>
        <a v-if="props.row.upcoming_events && props.row.upcoming_events.length > 0 && props.row.upcoming_events[0].meetup_url" @click="onClickUrl(props.row)">
          {{ props.row.author_user_id === "meetup_migrate_user" ? (props.row.is_migrated ? "Y" : "N") : "" }}
        </a>
        <span v-else>
          {{ props.row.author_user_id === "meetup_migrate_user" ? (props.row.is_migrated ? "Y" : "N") : "" }}
        </span>
      </b-table-column>
      <b-table-column v-slot="props" field="start" label="Start">
        {{ getStart(props.row.rruleset_text) }}
      </b-table-column>
      <b-table-column v-slot="props" field="day" label="Day">
        {{ getDay(props.row.rruleset_text) }}
      </b-table-column>
      <b-table-column v-slot="props" field="time" label="Time">
        {{ getTime(props.row.rruleset_text) }}
      </b-table-column>
      <b-table-column v-slot="props" field="next" label="Next">
        {{ (props.row.upcoming_events && props.row.upcoming_events.length > 0) ? format(new Date(props.row.upcoming_events[0].starts_at || props.row.upcoming_events[0].scheduled_starts_at), "MM/dd/yy") : "" }}
      </b-table-column>
      <b-table-column v-slot="props" field="created_at" label="Created" sortable>
        {{ format(new Date(props.row.created_at), "MM/dd/yy") }}
      </b-table-column>

      <template slot="bottom-left">
        <div class="is-flex is-flex-direction-column">
          <div class="mb-2 is-flex is-flex-direction-row">
            <div>
              <b-select v-model="limitSelection">
                <option value="10">10 per page</option>
                <option value="20">20 per page</option>
                <option value="50">50 per page</option>
                <option value="100">100 per page</option>
              </b-select>
            </div>
            <div class="mt-2 ml-1"><b>Total</b>: {{ dataCount }}</div>
          </div>
          <div v-if="checkedRows.length > 0">
            <b-field label="Checked Actions">
              <Facet
                v-model="facetActionSelection"
                class="is-align-items-center mr-2 mt-1"
                :options="facetAction.options"
                :value="facetActionSelection"
                @input="onAction"
              />
            </b-field>
            <section v-if="isMigrating" class="content elevation-1 boxed mb-2">
              <p class="is-size-5 has-text-weight-semibold">Migrate Checked Series?</p>
              <UndoConfirmButtons :confirm-enabled="true" @undo="unAction" @confirm="migrateSelected" />
            </section>
            <section v-if="isUnmigrating" class="content elevation-1 boxed mb-2">
              <p class="is-size-5 has-text-weight-semibold">Un-Migrate Checked Series?</p>
              <UndoConfirmButtons :confirm-enabled="true" @undo="unAction" @confirm="unmigrateSelected" />
            </section>
          </div>
        </div>
      </template>

      <template slot="empty">
        <EmptyState background="" class="is-flex-column">
          No event series found.
          <b-button @click="clearSearch()">
            reset search
          </b-button>
        </EmptyState>
      </template>
    </b-table>
  </div>
</template>

<script>
import { format_title } from "@/filters";
import { format } from "date-fns";
import { isNotDeleted } from "@/models/events/predicates.js";
import { SERIES_LIST_FOR_ADMIN, SERIES_MIGRATE, SERIES_UNMIGRATE } from "@/models/series/operations.gql";
import Facet from "@/components/forms/Facet.vue";
import EmptyState from "@/components/common/EmptyState.vue";
import { rrulestr } from "rrule";
import rrule from "@/rrule.js";
import { toLocalDate } from "@/dates.js";
import UndoConfirmButtons from "@/components/common/UndoConfirmButtons.vue";

export default {
  name: "EventTemplateAdministration",
  components: {
    Facet,
    EmptyState,
    UndoConfirmButtons
  },
  data() {
    const DEFAULT_ACTION = { label: "Select", action: undefined };
    return {
      data: [],
      dataCount: 0,
      currentPage: 1,
      limit: 20,
      limitSelection: "20",
      offset: 0,
      searchStringTitle: "",
      searchStringHost: "",
      orderBy: {
        id: "asc"
      },
      format,
      format_title,
      onlyShowScheduled: true,
      onlyShowCancelled: false,
      onlyShowPhysical: false,
      onlyShowVirtual: false,
      checkedRows: [],
      facetActionSelection: DEFAULT_ACTION,
      facetAction: Object.freeze(
        (() => {
          return {
            DEFAULT_ACTION,
            options: [
              DEFAULT_ACTION,
              { label: "Migrate", action: "migrate" },
              { label: "Un-Migrate", action: "unmigrate" }
            ]
          };
        })()
      ),
      isMigrating: false,
      isUnmigrating: false
    };
  },
  computed: {
    apolloVars() {
      var where = {
        ...isNotDeleted()
      };
      if (this.searchStringTitle) {
        where = {
          ...where,
          title: { _ilike: `%${this.searchStringTitle}%` }
        };
      }
      if (this.searchStringHost) {
        where = {
          ...where,
          members: {
            organizer_granted_at: { _is_null: false },
            user: { username: { _ilike: `%${this.searchStringHost}%` } }
          }
        };
      }
      if (this.onlyShowScheduled) {
        where = {
          ...where,
          cancelled_by_user_at: { _is_null: true }
        };
      }
      if (this.onlyShowCancelled) {
        where = {
          ...where,
          cancelled_by_user_at: { _is_null: false }
        };
      }
      if (this.onlyShowPhysical) {
        where = {
          ...where,
          venue: { is_virtual: { _eq: false } }
        };
      }
      if (this.onlyShowVirtual) {
        where = {
          ...where,
          venue: { is_virtual: { _eq: true } }
        };
      }
      return {
        limit: this.limit,
        offset: this.offset,
        where: where,
        orderBy: [this.orderBy]
      };
    }
  },
  watch: {
    limitSelection(val) {
      //this.currentPage = newPage;
      //this.offset = (newPage - 1) * this.limit;
      this.limit = parseInt(val);
    }
  },
  mounted() {
    if (this.$route.query["searchStringHost"]) {
      this.searchStringHost = this.$route.query["searchStringHost"];
    }
  },
  methods: {
    onPageChanged(newPage) {
      this.currentPage = newPage;
      this.offset = (newPage - 1) * this.limit;
    },
    onSort(field, dir) {
      if (field == "slug") {
        this.orderBy = {
          slug: dir
        };
      } else if (field == "title") {
        this.orderBy = {
          title: dir
        };
      } else if (field == "is_virtual") {
        this.orderBy = {
          original_venue_was_virtual: dir
        };
      } else if (field == "is_migrated") {
        this.orderBy = {
          is_migrated: dir
        };
      } else if (field == "created_at") {
        this.orderBy = {
          created_at: dir
        };
      }
    },
    clearSearch() {
      this.offset = 0;
      this.currentPage = 1;
      this.searchStringTitle = "";
      this.searchStringHost = "";
    },
    updateSearch(refname) {
      this.$refs[refname].focus();
      this.offset = 0;
      this.currentPage = 1;
    },
    unAction() {
      this.isMigrating = false;
      this.isUnmigrating = false;
      this.facetActionSelection = this.DEFAULT_ACTION;
    },
    onAction(val) {
      this.unAction();
      if (val.action === "migrate") {
        this.isMigrating = true;
      }
      if (val.action === "unmigrate") {
        this.isUnmigrating = true;
      }
    },
    migrate(event_template_id) {
      this.$apollo.mutate({
        mutation: SERIES_MIGRATE,
        variables: {
          event_template_id: event_template_id
        }
      });
    },
    unmigrate(event_template_id) {
      this.$apollo.mutate({
        mutation: SERIES_UNMIGRATE,
        variables: {
          event_template_id: event_template_id
        }
      });
    },
    migrateSelected() {
      for (var i = 0; i < this.checkedRows.length; i++) {
        var u = this.checkedRows[i];
        this.migrate(u.id);
      }
      this.unAction();
    },
    unmigrateSelected() {
      for (var i = 0; i < this.checkedRows.length; i++) {
        var u = this.checkedRows[i];
        //just double-checking it's a meetup event since suw events must always be marked "migrated"
        if (u.author_user_id === "meetup_migrate_user") {
          this.unmigrate(u.id);
        }
      }
      this.unAction();
    },
    onClickUrl(row) {
      if (row.upcoming_events[0].meetup_url) {
        window.open(row.upcoming_events[0].meetup_url, "_blank");
      }
    },
    onClickTitle(row) {
      let routeData = this.$router.resolve({
        name: "series_lookup",
        params: {
          event_template_id: row.id
        }
      });
      window.open(routeData.href, "_blank");
    },
    selectAny() {
      this.onlyShowScheduled = false;
      this.onlyShowCancelled = false;
    },
    selectScheduled() {
      this.onlyShowScheduled = true;
      this.onlyShowCancelled = false;
    },
    selectCancelled() {
      this.onlyShowScheduled = false;
      this.onlyShowCancelled = true;
    },
    getStart(rruleset_text) {
      const rruleset = rrulestr(rruleset_text);
      let dateToUse = rruleset.origOptions.dtstart;
      let result = format(toLocalDate(dateToUse), "MM/dd/yyyy");
      return result;
    },
    getDay(rruleset_text) {
      const rruleset = rrulestr(rruleset_text);
      return rrule.dayOfWeek(rruleset);
    },
    getTime(rruleset_text) {
      const rruleset = rrulestr(rruleset_text);
      let dateToUse = rruleset.origOptions.dtstart;
      let result = format(toLocalDate(dateToUse), "hh:mm a");
      return result;
    },
    selectAnyVenue() {
      this.onlyShowPhysical = false;
      this.onlyShowVirtual = false;
    },
    selectPhysicalVenue() {
      this.onlyShowPhysical = true;
      this.onlyShowVirtual = false;
    },
    selectVirtualVenue() {
      this.onlyShowPhysical = false;
      this.onlyShowVirtual = true;
    }
  },
  apollo: {
    data: {
      query: SERIES_LIST_FOR_ADMIN,
      debounce: 500,
      variables() {
        return this.apolloVars;
      },
      update({ event_templates, event_templates_aggregate }) {
        this.dataCount = event_templates_aggregate.aggregate.count;
        return event_templates;
      }
    }
  }
};
</script>

<style lang="scss">
.cancel-dropdown {
  display: flex;
  flex-direction: row;
  align-items: center;
  > span {
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    margin: 0 10px 0 0;
  }
  > span,
  .button {
    font-size: 16px;
  }
  > span {
    display: block;
  }
  .button {
    border: solid 1px #ddd;
    padding: 7px 16px 7px 16px;
    font-weight: 400;
    height: auto;
    &:hover,
    &:focus {
      background: $pink;
      color: #fff !important;
      box-shadow: none;
      border: solid 1px shade($pink, 10%);
    }
    .icon {
      @include desktop {
        margin-left: 30px !important;
      }
    }
  }
}
</style>
