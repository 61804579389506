export const whereIsAssignedToEventTemplate = ({ id }) => ({
  starts_at: { _gte: "now()" },
  event_template_id: { _eq: id }
});

export const whereCurrentUserRSVPd = ({ id }) => ({
  // don't filter out events cancelled by the materializer for this one
  // as we still want users to see events they RSVPd to even if they got
  // cancelled
  event_calendars: {
    _and: [
      { user_id: { _eq: id } },
      { rsvped_at: { _is_null: false } }
    ]
  }
});

export const whereCurrentUserSubscribed = ({ id }) => ({
  template: {
    members: {
      user_id: { _eq: id }
    }
  }
});

export const whereCurrentUserIsOrganizer = ({ id }) => ({
  cancelled_by_materializer_at: { _is_null: true },
  template: {
    members: {
      user_id: { _eq: id },
      organizer_granted_at: { _is_null: false }
    }
  }
});

export const whereNearGPS = ({ lat, lng, meters }) => ({
  // now add GPS to the search if we need to
  template: {
    venue: {
      location: {
        _st_d_within: {
          distance: meters,
          from: {
            type: "Point",
            coordinates: [lat, lng]
          }
        }
      }
    }
  }
});

// set `is_virtual` to null or just don't include this predicate to show both types
export const whereIsVirtual = ({ is_virtual }) => ({
  _or: [
    {
      venue: {
        is_virtual: { _eq: is_virtual }
      }
    },
    {
      template: {
        venue: {
          is_virtual: { _eq: is_virtual }
        }
      }
    }
  ]
});

export const whereNotCancelled = () => ({
  cancelled_by_materializer_at: { _is_null: true },
  template: {
    cancelled_by_user_at: { _is_null: true }
  }
});

export const isNotDeleted = () => ({
  deleted_at: { _is_null: true }
});

export const whereEndsAtInRange = (startDate, endDate) => ({
  ends_at: {
    _gte: startDate,
    _lte: endDate
  }
});

export const whereStartsAtInRange = (start_date, end_date) => ({
  _or: [
    {
      starts_at: {
        _is_null: false,
        _gte: start_date,
        _lte: end_date
      }
    },
    {
      scheduled_starts_at: {
        _gte: start_date,
        _lte: end_date
      }
    }
  ]
});

export const whereEventVenueIncludes = venue_ids => ({
  _or: [
    {
      venue_id: {
        _in: venue_ids
      }
    },
    {
      template: {
        venue_id: {
          _in: venue_ids
        }
      }
    }
  ]
});
