<template>
  <b-dropdown class="no-left" @change="onChanged">
    <div slot="trigger">
      <a class="link">
        {{ triggerText }}
        <span class="caret">
          &#8964;
        </span>
      </a>
    </div>
    <b-dropdown-item
      v-for="o in options"
      :key="o.label"
      :value="o"
      :disabled="value === o"
    >
      {{ o.label || o }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  name: "Facet",
  props: {
    value: {
      type: Object,
      required: true
    },
    options: {
      type: Array,
      required: true
    }
  },
  computed: {
    triggerText() {
      return this.value.label || this.value || "?";
    }
  },
  methods: {
    onChanged(newVal) {
      this.$emit("input", newVal);
    }
  }
};
</script>

<style lang="scss" scoped>
.no-left {
  margin-left: 0;
}
.link {
  border-bottom: 1px dashed $primary;
  position: relative;
  padding-right: 1rem;
  .caret {
    position: absolute;
    top: -0.35rem;
  }
}
</style>
